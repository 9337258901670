<template>
  <div @click="closeOpenSearch">
    <b-card-code title="Add Bundle">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- title -->
            <b-col md="6">
              <b-form-group label="Title" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="bundleData.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- cover -->
            <b-col md="6">
              <b-form-group label="Cover" label-for="cover">
                <validation-provider
                  #default="{ errors }"
                  name="Cover"
                  rules="required"
                >
                  <b-form-file
                    v-model="ProfilePictureBase64"
                    :state="errors.length > 0 ? false : null"
                    @change="uploadImage"
                    id="ProfilePictureBase64"
                    accept="image/*"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Start Date -->
            <b-col md="6">
              <b-form-group label="Start Date" label-for="start date">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <flat-pickr
                    v-model="bundleData.startdate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- End Date -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="end date"
                rules="required"
              >
                <b-form-group label="End Date" label-for="end date">
                  <flat-pickr
                    v-model="bundleData.enddate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- type -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="typeSelect"
                    :state="typeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- usernumbers -->
            <b-col md="6">
              <b-form-group label="User Numbers" label-for="user numbers">
                <validation-provider
                  #default="{ errors }"
                  name="user numbers"
                  rules="required|integer"
                >
                  <b-form-input
                    id="usernumbers"
                    v-model="bundleData.usernumbers"
                    :state="errors.length > 0 ? false : null"
                    placeholder="User Numbers"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- descriptuion -->
            <b-col sm="12">
              <div>
                <!-- Row Loop -->
                <b-row v-for="(item, index) in tranLocaleSelect" :key="index">
                  <!-- translation language -->
                  <b-col md="6">
                    <validation-provider
                      #default="{ errors }"
                      name="language"
                      rules="required"
                    >
                      <b-form-group
                        label="Language"
                        label-for="language"
                        :state="errors.length > 0 ? false : null"
                      >
                        <v-select
                          :id="'language' + index"
                          v-model="tranLocaleSelect[index]"
                          :disabled="tranLocaleSelect[index].disa"
                          :state="
                            tranLocaleSelect[index] === null ? false : true
                          "
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="localeOptions"
                          :selectable="
                            (option) => !option.value.includes('select_value')
                          "
                          label="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- Description -->
                  <b-col md="12">
                    <b-form-group label="Description" label-for="description">
                      <validation-provider
                        #default="{ errors }"
                        name="description"
                        rules="required"
                      >
                        <vue-editor
                          :id="'description' + index"
                          v-model="bundleData.translations[index].description"
                          :state="errors.length > 0 ? false : null"
                          rows="2"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button -->
                  <b-col lg="2" md="3" class="mb-50">
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      v-show="!tranLocaleSelect[index].disa"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItemTrans(index)"
                    >
                      <feather-icon icon="XIcon" class="mr-25" />
                      <span>Remove</span>
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- add new button -->
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgainTrans"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!-- products -->
            <b-col cols="12">
              <b-card title="Products">
                <b-row>
                  <b-col cols="12">
                    <div style="position: relative">
                      <b-row>
                        <b-col cols="10">
                          <validation-provider
                            #default="{ errors }"
                            name="item"
                            rules="required"
                          >
                            <b-form-input
                              id="searchText"
                              type="search"
                              :state="
                                bundleData.variants.length == 0 ? false : true
                              "
                              v-model="searchText"
                              placeholder="Search For Add Item"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-col>
                        <b-col cols="2">
                          <b-button
                            @click="searchResult(searchText)"
                            variant="outline-primary"
                            >Search</b-button
                          >
                        </b-col>
                        <b-alert
                          variant="warning"
                          style="margin: 0.438rem 1rem"
                          :show="noResult"
                        >
                          <div class="alert-body">No Result Found.</div>
                        </b-alert>
                        <b-col
                          style="
                            position: absolute;
                            z-index: 9999;
                            padding: 0.438rem 1rem;
                            top: 35px;
                            height: 380px !important;
                            overflow: scroll;
                            scroll-padding: 0px !important;
                            padding-right: 0;
                          "
                          v-if="resultShow"
                          cols="9"
                        >
                          <b-list-group>
                            <b-list-group-item
                              @click="
                                addProduct(item.id, item.name, item.price)
                              "
                              v-for="(item, index) in productsSR"
                              :key="index"
                            >
                              <feather-icon icon="" class="mr-75" />
                              <p style="font-weight: 600">- {{ item.name }}</p>
                              <small style="padding-left: 10px"
                                >Price: {{ item.price }}</small
                              >
                            </b-list-group-item>
                          </b-list-group>
                        </b-col>
                        <b-col cols="12">
                          <b-row>
                            <b-col
                              v-if="bundleData.variants.length"
                              class="pt-1 pb-1"
                              cols="12"
                            >
                              <h2
                                v-if="bundleData.variants.length"
                                class="mr-3 pt-2"
                              >
                                Items List:
                              </h2>
                              <!-- Row Loop -->
                              <b-row
                                v-if="bundleData.variants.length"
                                v-for="(item, index) in bundleData.variants"
                                :key="index"
                              >
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                                <b-col class="pl-5" md="3">
                                  <h4 style="line-height: 2.5rem">
                                    {{ item.name }}
                                  </h4>
                                  <small style="padding-left: 10px"
                                    >ID: {{ item.variant_id }}</small
                                  >
                                  <small style="padding-left: 10px"
                                    >Price: {{ item.price }}</small
                                  >
                                </b-col>
                                <b-col md="1">
                                  <b-form-group
                                    label="Quantity"
                                    label-for="Quantity"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Quantity"
                                    >
                                      <b-form-input
                                        min="1"
                                        id="Quantity"
                                        type="number"
                                        v-model="item.quantity"
                                        @input="recalcCost()"
                                        :state="
                                          errors.length > 0 ? false : null
                                        "
                                        placeholder="15"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <!-- profit -->
                                <b-col v-if="!item.gift" md="1">
                                  <b-form-group
                                    label="Profit"
                                    label-for="profit"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="profit"
                                      rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                                    >
                                      <b-form-input
                                        id="profit"
                                        :disabled="checkedType"
                                        v-model="item.profit"
                                        @input="changeProfit(index)"
                                        :state="
                                          errors.length > 0 ? false : null
                                        "
                                        placeholder="profit"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                      <small
                                        class="text-danger"
                                        v-show="profit_percent"
                                        >profit must be between 0 - 100</small
                                      >
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <!-- amount -->
                                <b-col v-if="!item.gift" md="1">
                                  <b-form-group
                                    label="discount"
                                    label-for="discount"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="discount"
                                      rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                                    >
                                      <b-form-input
                                        id="discount"
                                        :disabled="checkedType"
                                        v-model="item.discount"
                                        @input="changeAmount(index)"
                                        :state="
                                          errors.length > 0 ? false : null
                                        "
                                        placeholder="discount"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                      <small
                                        class="text-danger"
                                        v-show="discount_percent"
                                        >Discount must be between 0 - 99</small
                                      >
                                      <small
                                        class="text-danger"
                                        v-show="discount_fixed"
                                        >Discount must be less than price</small
                                      >
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="1" class="text-center">
                                  <label style="font-size: 15px" for="Master"
                                    >Master</label
                                  >
                                  <input
                                    type="radio"
                                    name="Master"
                                    @click="removeGift(index)"
                                    :id="item.variant_id"
                                    :value="{
                                      id: item.variant_id,
                                      name: item.name,
                                    }"
                                    v-model="masterVariant_id"
                                  />
                                </b-col>
                                <b-col
                                  v-show="
                                    masterVariant_id.id !== item.variant_id
                                  "
                                  md="1"
                                  class="text-center"
                                >
                                  <label
                                    for="Gift"
                                    style="
                                      font-size: 15px;
                                      margin-right: 15px;
                                      margin-left: 10px;
                                    "
                                    >Gift</label
                                  >
                                  <input
                                    type="checkbox"
                                    @change="giftClick(index)"
                                    :id="item.variant_id"
                                    value="0"
                                    v-model="item.gift"
                                  />
                                </b-col>
                                <b-col
                                  v-if="!item.gift"
                                  md="3"
                                  class="text-center"
                                >
                                  <label
                                    style="
                                      font-size: 15px;
                                      margin-right: 15px;
                                      margin-left: 10px;
                                    "
                                    >Sale Price / per item</label
                                  ><br />
                                  <label
                                    style="
                                      font-size: 15px;
                                      margin-right: 15px;
                                      margin-left: 10px;
                                    "
                                  >
                                    {{ item.salePrice }}
                                  </label>
                                </b-col>
                                <!-- Remove Button -->
                                <b-col md="12" class="mb-50">
                                  <b-button
                                    block
                                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                    variant="outline-danger"
                                    class="mt-0 mt-md-2"
                                    @click="removeItem(index)"
                                  >
                                    <feather-icon icon="XIcon" class="mr-25" />
                                    <span>Remove</span>
                                  </b-button>
                                </b-col>
                                <b-col cols="12">
                                  <hr />
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col class="mt-2" cols="12">
              <h3>Total: {{ beforeAny }}</h3>
              <h3>Offer Price: {{ total }}</h3>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
            <!--submit button -->
            <b-col>
              <b-button
                size="lg"
                :disabled="disabled_submit"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
import { VueEditor } from 'vue2-editor'
export default {
  components: {
    BCardCode,
    VueEditor,
 
    
  },
  
  mixins: [heightTransition],
  data() {
    return {
      showDismissibleAlert: false,
      ProfilePictureBase64: [],
      noResult: false,
      resultShow: false,
      productsSR: [],
      searchText: '',
      profit_percent: false,
      discount_percent: false,
      discount_fixed: false,
      disabled_submit: true,
      cost: '',
      cost2: '',
      total: '',
      beforeAny: '',
      offerPrice: '',
      tranLocaleSelect: [
        {
          value: 'en',
          text: 'English',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      variants: [],
      masterVariant_id: {
        id: '',
        name: '',
      },
      bundleData: {
        type: '',
        startdate: '',
        enddate: '',
        usernumbers: '',
        title: '',
        cover: '',
        masterVariant_id: null,
        variants: [],
        translations: [
          {
            locale: '',
            description: '',
          },
          {
            locale: '',
            description: '',
          },
        ],
      },
      checkedType: true,
      typeSelect: null,
      typeOptions: [
        {
          value: 'percentage',
          text: 'Percentage',
        },
        {
          value: 'fixed_price',
          text: 'Fixed Price',
        },
      ],
      errors_back: [],
      required,
      integer,
    }
  },
  watch: {
    typeSelect: function () {
      if (this.typeSelect.value != null && this.typeSelect.value != '') {
        this.checkedType = false
        this.cost = 0
        if (this.bundleData.variants.length > 0) {
          for (let index in this.bundleData.variants) {
            if (this.typeSelect.value == 'percentage') {
              const percent =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].discount) /
                100
              const percentProfite =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].profit) /
                100
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price - percent + percentProfite
            } else {
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price -
                this.bundleData.variants[index].discount +
                parseFloat(this.bundleData.variants[index].profit)
            }
            const qun = parseInt(this.bundleData.variants[index].quantity)
            const pri = parseFloat(this.bundleData.variants[index].salePrice)
            this.cost += qun * pri
            this.total = this.cost
          }
        }
      } else {
        this.checkedType = true
        if (this.bundleData.variants.length > 0) {
          this.cost = 0
          for (let ind in this.bundleData.variants) {
            this.bundleData.variants[ind].profit = ''
            this.bundleData.variants[ind].discount = ''
            this.bundleData.variants[ind].salePrice =
              this.bundleData.variants[ind].price
            const qun = parseInt(this.bundleData.variants[index].quantity)
            const pri = parseFloat(this.bundleData.variants[index].salePrice)
            this.cost += qun * pri
            this.total = this.cost
          }
        }
      }
    },
  },
  created() {},
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.bundleData.cover.includes(',')) {
            this.bundleData.cover = this.bundleData.cover
              .split(',')
              .map((item) => item.trim())
            this.bundleData.cover = this.bundleData.cover[1]
          }
          this.bundleData.type = this.typeSelect.value
          for (let trn in this.bundleData.translations) {
            this.bundleData.translations[trn].locale =
              this.tranLocaleSelect[trn].value
          }
          this.bundleData.masterVariant_id = this.masterVariant_id.id

          const sendData = this.bundleData
          for (let variant in sendData.variants) {
            delete sendData.variants[variant].name
            delete sendData.variants[variant].price
            delete sendData.variants[variant].salePrice
            if (sendData.variants[variant].gift == true) {
              sendData.variants[variant].discount = 0
              sendData.variants[variant].profit = 0
            }
          }

          axios
            .post('bundles', sendData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/bundle/index')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.bundleData.cover = e.target.result
      }
      reader.readAsDataURL(file)
    },
    searchResult(text) {
      this.noResult = false
      axios
        .get('agency/variants/' + text)
        .then((result) => {
          const data = result.data.data

          this.productsSR.length = 0
          for (let index in data) {
            if (data[index].translation != null) {
              this.productsSR.push({
                id: data[index].id,
                name: data[index].translation.name,
                price: data[index].original_price,
              })
            } else {
              this.productsSR.push({
                id: data[index].id,
                name: 'undefinded',
                price: data[index].original_price,
              })
            }
          }
          if (this.productsSR.length > 0) {
            this.resultShow = true
          } else {
            this.noResult = true
            this.resultShow = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    removeGift(index) {
      if (this.bundleData.variants[index].gift == true) {
        this.cost = 0
        const qun = parseInt(this.bundleData.variants[index].quantity)
        const pri = parseFloat(this.bundleData.variants[index].price)
        this.cost += qun * pri
        this.total = this.cost
      }
      this.bundleData.variants[index].gift = false
    },
    giftClick(index) {
      if (this.bundleData.variants[index].gift == true) {
        const qun = parseInt(this.bundleData.variants[index].quantity)
        const pri = parseFloat(this.bundleData.variants[index].price)
        this.cost -= qun * pri
        this.total = this.cost
      } else {
        const qun = parseInt(this.bundleData.variants[index].quantity)
        const pri = parseFloat(this.bundleData.variants[index].price)
        this.cost += qun * pri
        this.total = this.cost
      }
      this.bundleData.variants[index].profit = ''
      this.bundleData.variants[index].discount = ''
    },
    addProduct(id, name, price) {
      this.resultShow = false
      if (this.bundleData.variants.length == 0) {
        this.masterVariant_id = {
          id: id,
          name: name,
        }
      }
      this.disabled_submit = true

      this.bundleData.variants.push({
        variant_id: id,
        price: price,
        quantity: '1',
        name: name,
        gift: false,
        profit: '',
        discount: '',
        salePrice: price,
      })
      // this.bundleData.variants = [...new Map(this.bundleData.variants.map(item => [item.id, item])).values()]
      this.cost = 0
      this.cost2 = 0
      for (let index in this.bundleData.variants) {
        const qun = parseInt(this.bundleData.variants[index].quantity)
        const pri = parseFloat(this.bundleData.variants[index].salePrice)
        const o_Pri = parseFloat(this.bundleData.variants[index].price)
        this.cost += qun * pri
        this.cost2 += qun * o_Pri
        this.total = this.cost
        this.beforeAny = this.cost2
      }
      this.productsSR.length = 0
    },
    changeProfit(index) {
      if (this.typeSelect.value == 'percentage') {
        if (
          this.bundleData.variants[index].profit >= 0 &&
          this.bundleData.variants[index].profit <= 100
        ) {
          if (
            this.bundleData.variants[index].profit != '' &&
            this.bundleData.variants[index].profit != null &&
            this.typeSelect.value != null &&
            this.typeSelect.value != '' &&
            this.bundleData.variants[index].discount != '' &&
            this.bundleData.variants[index].discount != null
          ) {
            if (this.typeSelect.value == 'percentage') {
              const percent =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].discount) /
                100
              const percentProfite =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].profit) /
                100
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price - percent + percentProfite
            } else {
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price -
                this.bundleData.discount +
                parseFloat(this.bundleData.variants[index].profit)
            }
            this.disabled_submit = false
          } else {
            this.bundleData.variants[index].salePrice =
              this.bundleData.variants[index].price
            this.disabled_submit = true
          }
          this.cost = 0
          for (let index in this.bundleData.variants) {
            const qun = parseInt(this.bundleData.variants[index].quantity)
            const pri = parseFloat(this.bundleData.variants[index].salePrice)
            this.cost += qun * pri
            this.total = this.cost
          }
          this.profit_percent = false
        } else {
          this.profit_percent = true
          this.disabled_submit = true
        }
      } else {
        if (
          this.bundleData.variants[index].profit != '' &&
          this.bundleData.variants[index].profit != null &&
          this.typeSelect.value != null &&
          this.typeSelect.value != '' &&
          this.bundleData.variants[index].discount != '' &&
          this.bundleData.variants[index].discount != null
        ) {
          if (this.typeSelect.value == 'percentage') {
            const percent =
              (this.bundleData.variants[index].price *
                this.bundleData.variants[index].discount) /
              100
            const percentProfite =
              (this.bundleData.variants[index].price *
                this.bundleData.variants[index].profit) /
              100
            this.bundleData.variants[index].salePrice =
              this.bundleData.variants[index].price - percent + percentProfite
          } else {
            this.bundleData.variants[index].salePrice =
              this.bundleData.variants[index].price -
              this.bundleData.discount +
              parseFloat(this.bundleData.variants[index].profit)
          }
          this.disabled_submit = false
        } else {
          this.bundleData.variants[index].salePrice =
            this.bundleData.variants[index].price
          this.disabled_submit = true
        }
        this.cost = 0
        for (let index in this.bundleData.variants) {
          const qun = parseInt(this.bundleData.variants[index].quantity)
          const pri = parseFloat(this.bundleData.variants[index].salePrice)
          this.cost += qun * pri
          this.total = this.cost
        }
      }
    },
    changeAmount(index) {
      if (this.typeSelect.value == 'percentage') {
        if (
          this.bundleData.variants[index].discount >= 0 &&
          this.bundleData.variants[index].discount < 100
        ) {
          if (
            this.bundleData.variants[index].profit != '' &&
            this.bundleData.variants[index].profit != null &&
            this.typeSelect.value != null &&
            this.typeSelect.value != '' &&
            this.bundleData.variants[index].discount != '' &&
            this.bundleData.variants[index].discount != null
          ) {
            if (this.typeSelect.value == 'percentage') {
              const percent =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].discount) /
                100
              const percentProfite =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].profit) /
                100
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price - percent + percentProfite
            } else {
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price -
                this.bundleData.discount +
                parseFloat(this.bundleData.variants[index].profit)
            }
            this.disabled_submit = false
          } else {
            this.disabled_submit = true
            this.bundleData.variants[index].salePrice =
              this.bundleData.variants[index].price
          }
          this.cost = 0
          for (let index in this.bundleData.variants) {
            const qun = parseInt(this.bundleData.variants[index].quantity)
            const pri = parseFloat(this.bundleData.variants[index].salePrice)
            this.cost += qun * pri
            this.total = this.cost
          }
          this.discount_percent = false
        } else {
          this.discount_percent = true
          this.disabled_submit = true
        }
      } else {
        if (
          this.bundleData.variants[index].discount <
          this.bundleData.variants[index].price
        ) {
          if (
            this.bundleData.variants[index].profit != '' &&
            this.bundleData.variants[index].profit != null &&
            this.typeSelect.value != null &&
            this.typeSelect.value != '' &&
            this.bundleData.variants[index].discount != '' &&
            this.bundleData.variants[index].discount != null
          ) {
            if (this.typeSelect.value == 'percentage') {
              const percent =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].discount) /
                100
              const percentProfite =
                (this.bundleData.variants[index].price *
                  this.bundleData.variants[index].profit) /
                100
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price - percent + percentProfite
            } else {
              this.bundleData.variants[index].salePrice =
                this.bundleData.variants[index].price -
                this.bundleData.discount +
                parseFloat(this.bundleData.variants[index].profit)
            }
            this.disabled_submit = false
          } else {
            this.disabled_submit = true
            this.bundleData.variants[index].salePrice =
              this.bundleData.variants[index].price
          }
          this.cost = 0
          for (let index in this.bundleData.variants) {
            const qun = parseInt(this.bundleData.variants[index].quantity)
            const pri = parseFloat(this.bundleData.variants[index].salePrice)
            this.cost += qun * pri
            this.total = this.cost
          }
          this.discount_fixed = false
        } else {
          this.discount_fixed = true
          this.disabled_submit = true
        }
      }
    },
    recalcCost() {
      this.cost = 0
      this.cost2 = 0
      for (let index in this.bundleData.variants) {
        const qun = parseInt(this.bundleData.variants[index].quantity)
        const pri = parseFloat(this.bundleData.variants[index].salePrice)
        const o_Pri = parseFloat(this.bundleData.variants[index].price)
        this.cost += qun * pri
        this.cost2 += qun * o_Pri
        this.total = this.cost
        this.beforeAny = this.cost2
      }
    },
    closeOpenSearch() {
      if (this.resultShow == true) {
        this.resultShow = false
      }
    },
    removeItem(index) {
      if (this.bundleData.variants.length > 1) {
        this.bundleData.variants.splice(index, 1)
        this.cost = 0
        this.cost2 = 0
        for (let index in this.bundleData.variants) {
          if (this.bundleData.variants[index].gift == false) {
            const qun = parseInt(this.bundleData.variants[index].quantity)
            const pri = parseFloat(this.bundleData.variants[index].salePrice)
            this.cost += qun * pri
            this.total = this.cost
          }
          const qun = parseInt(this.bundleData.variants[index].quantity)
          const o_Pri = parseFloat(this.bundleData.variants[index].price)
          this.cost2 += qun * o_Pri
          this.beforeAny = this.cost2
        }
        this.masterVariant_id = {
          id: this.bundleData.variants[0].variant_id,
          name: this.bundleData.variants[0].name,
        }
      } else {
        this.bundleData.variants.splice(index, 1)
        this.cost = 0
        this.cost2 = 0
        this.total = this.cost
        this.beforeAny = this.cost2
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // REPETED FORM
    repeateAgainTrans() {
      this.bundleData.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({})
    },
    // remove function to delete single translation from category
    removeItemTrans(index) {
      if (this.data.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.bundleData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
  },
}
</script>
 
<style lang="scss">


@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
.repeater-form {
  transition: 0.35s height;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}
.app-auto-suggest {
  position: relative;
}
.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
}
.suggestion-group-title {
  font-weight: 500;
  padding: 0.75rem 1rem 0.25rem;
}
.suggestion-group-suggestion {
  padding: 0.75rem 1rem;
}
.suggestion-current-selected {
  background-color: $body-bg;
  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>